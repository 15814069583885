import styled from 'styled-components'

import colors from '../../../styles/colors'
import { device } from '../../../styles/media'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

export const StyledContainer = styled(Container)`
  color: ${colors.textDark};
  height: auto;
  min-height: 500px;
  padding-bottom: 75px;
  padding-top: 75px;
`

export const StyledGrid = styled(Grid)`
  height: 100%;
  padding-bottom: 50px;
  padding-top: 50px;
`

export const StyledGridItem = styled(Grid)`
  min-height: fit-content;
`

export const StyledH2 = styled.h2`
  font-size: 3.25rem;
  font-weight: 500;
  text-align: center;
`

export const StyledH3 = styled.h3`
  font-size: 1.5rem;
  margin-top: 75px;
`

export const StyledP = styled.p`
  color: ${colors.textMedium};
  font-size: 1.25rem;
  line-height: 1.75;
  padding: 0 5rem;
  text-align: left;

  @media ${device.mobile} {
    padding: 0;
  }
`
