import React from 'react'

import { MainShape, StyledContainer, StyledH2, StyledLink, StyledP, StyledSpan } from './Main.style'

import Grid from '@material-ui/core/Grid'

const Main = () => (
  <Grid container>
    <MainShape item>
      <StyledContainer>
        <StyledH2>Case Studies</StyledH2>
        <StyledP>
          <StyledLink to="/">Home</StyledLink>
          <StyledSpan> / Case Studies</StyledSpan>
        </StyledP>
      </StyledContainer>
    </MainShape>
  </Grid>
)

export default Main
