import React from 'react'

import { StyledContainer, StyledGrid, StyledGridItem, StyledH2, StyledH3, StyledP } from './Studies.style'

const Main = () => {
  const caseStudies = [
    {
      heading: 'Qcify',
      description:
        'Qcify strives to be the leading global innovator, developer, and provider of quality inspection solutions that will transform the way their customers and their business partners gather, manage, distribute and communicate quality control information.',
      challenge:
        'Qcify provides quality control technology to food processing professionals. These technologies allow professionals to run quality control inspections on batches of produced perishable goods. Detailed information about every batch of goods created gets generated to a database, and stored for information retrieval. They wanted to be able to display this data in a table, and generate certificates using that data. The app needed to be extremely user-friendly, as many users would not be technologically savvy. We were given a six-week deadline.',
      approach:
        'Each Qcify device is configured differently, so we needed to create a versatile application that could run on an assortment of configurations.',
      solution:
        'We created a simple yet versatile single-page desktop application, which can be accessed on both Windows and MacOS. On the new and improved application, users have the ability to connect and query data to an unlimited number of devices, creating a simple and highly efficient user experience. Aggregated data would be available at any time for user access on the app, and touch screen compatibility ensured that the application would be intuitive and user friendly.',
    },
    {
      heading: 'AgProz',
      description:
        "AgProz is aimed at utilizing the most current technologies to streamline the agricultural sales process. With AgProz, agriculture sales professionals can manage financial transactions, access sales specifics, and view growers' commodities.",
      challenge:
        'Using AgProz, agriculture salespeople are able to modify their sales techniques through access to information. Users can manage land information data, such as which plots of land grow specific crops, and salespeople are able to make notes. AgProz needed a website counterpart to orchestrate tasks that were more intensive, and harder to perform on the mobile app. A map featuring performance details and plot information would need to be created, to provide users a quick and easy visual reference.',
      approach:
        'Due to high variability of account statuses (i.e., suspended, terminated, in escrow, etc.), we needed to create an interface that would be capable of multiple accommodations. Every possible quirk and potential for complication needed to be considered. It was also crucial to create a fail safe that would ensure protection from damage to user’s accounts. We wanted the end result to be fast and efficient, without sacrificing versatility.',
      solution:
        'With account status variance in mind, a bulletproof app was created that would give users the ability to interact with their team through a quick and consistent medium. Users would now have the freedom to interact with one another without the fear of damage, theft, or intrusion to their accounts. We worked to create a data-intensive map that is accurate, reliable, and easily interpreted. The inclusion of a website dashboard allows management of financial details, access to team information, and the easy review of sales deals.',
    },
    {
      heading: 'Registered Artists',
      description:
        'Due to the escalating costs involved with filing a trademark application, many small businesses, social media entrepreneurs, and creative artists forego the vital legal protection of trademarking their personal brand and products, leaving them exposed in the marketplace.  Registered Artists is committed to helping businesses, entrepreneurs, and creatives, protect their unique name and brand.',
      challenge:
        'Registered Artists originally started as a small business aimed at brand identity protection. This protection was offered through the generation of trademark search reports, and filing applications to the United States Patent and Trademark Office. When searches yielded results, a handmade PDF document was created with the information. As it was not uncommon to return hundreds of results per state search, Registered Artists wanted to streamline this process, and expand the website’s profitability by adding some additional features. The changes that Registered Artists sought included an employee portal, and the ability to perform a variety of additional functions. Continuing to automate state and federal searches was key, plus the monitoring of these searches for trademark and business entities. Additional requirements included the ability for users to file trademark paperwork, upload files, and pay for forms.',
      approach:
        'We wanted to transform the site from just an online database for trademark searches and application filing, into a more full-service application that would benefit both users and employees. From a design standpoint, it was important to create a logo to give their brand a contemporary identity.',
      solution:
        'We created a versatile web application that allows users to perform multiple functions with ease. Customers are able to quickly monitor search terms, upload files, and pay for submitted forms. To enhance the site’s functionality, we also added an employee dashboard. The employee dashboard is an important addition that serves a number of the requested purposes, including reviewing any search reports, and generating necessary changes. The new and improved site readily aggregates   search results, and eliminates the need to hand-create PDF documents.',
    },
    {
      heading: 'Stanislaus County Workforce Development',
      description:
        "Department of Workforce Development/Alliance Worknet is a proud partner of America's Job Center of California (AJCC) in Stanislaus County. The AJCC brings varied local partners together to be an inclusive system to meet the needs of both job seekers and employers in the Stanislaus County area.",
      challenge:
        'Stanislaus County Workforce Development’s previous website was extremely sluggish, often non-responsive, and just generally outdated. Stanislaus County was seeking a cost-effective revamp of their website that would modernize the site’s appearance, and allow them to easily manage content.',
      approach:
        'he approach for this website was to be simple yet effective. We wanted to shift the website from antiquated to modern, while maintaining the focus of the site’s primary purpose. (Aesthetically/From a performance angle), our redesign would focus on creating an interface that provides fast, user-friendly content management. With budget in mind, we wanted to opt against enterprise level technologies, and instead incorporate a lightweight framework that would allow for quick changes without the need to connect to a server.',
      solution:
        'We worked with a content designer to create a thorough redesign of their website. The result was a complete modernization of the previously outdated site, including a more contemporary color palette, and up-to-date photos. The addition of business logos created a visual reference to make site components recognizable to users. Direct links were inserted where PDF pop-ups had previously been. The new site appears fresh and invigorated, without sacrificing practicality.',
    },
  ]

  const renderCaseStudies = caseStudies.map((study, index) => {
    return (
      <StyledContainer key={index}>
        <StyledH2>{study.heading}</StyledH2>
        <StyledP>{study.description}</StyledP>

        <StyledH3>The Challenge</StyledH3>
        <StyledP>{study.challenge}</StyledP>

        <StyledH3>The Approach</StyledH3>
        <StyledP>{study.approach}</StyledP>

        <StyledH3>The Solution</StyledH3>
        <StyledP>{study.solution}</StyledP>
      </StyledContainer>
    )
  })

  return (
    <StyledContainer>
      <StyledGrid container>{renderCaseStudies}</StyledGrid>
    </StyledContainer>
  )
}

export default Main
