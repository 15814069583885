import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Main from '../components/CaseStudies/Main/Main'
import Studies from '../components/CaseStudies/Studies/Studies'

const CaseStudies = () => (
  <Layout>
    <SEO title="Case Studies" />

    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Any business should be proud of their work. Here are only a small handful of the applications we've engineered."
      />
      <title>Case Studies | LaMar Software</title>
      <link rel="canonical" href="https://lamarsoftware.io/case-studies" />
    </Helmet>

    <Main />
    <Studies />
  </Layout>
)

export default CaseStudies
